<template>
  <section class="m-merchant-goods m-scroll">
    <!-- 头部导航 -->
    <div class="merchant-info">
      <img @click="$router.go(-1)"  class="info-left" src="@/assets/icon_back@2x.png" alt="back">
      <div class="info-right">
        <img :src="$store.state.merchant.pic_url" alt="merchant">
        <div class="center">
          <p>{{$store.state.merchant.title}}</p>
          <div>
            <div>
              <van-rate  v-model="$store.state.merchant.score" :size="12" color="#F03C18" void-icon="star" void-color="#ccc" allow-half readonly/>
            </div>
            <p>{{$store.state.merchant.score.toFixed(1)}}</p>
          </div>
        </div>
        <div class="followed" @click="handleFollowed()" v-if="$store.state.merchant.is_follow != null">Attentioned</div>
        <div class="followed" @click="handleFollowed()" v-if="$store.state.merchant.is_follow == null">Attention</div>
      </div>
    </div>
    <div class="merchant-search">
      <div class="search-box">
        <img src="@/assets/public/icon_search@2x.png" alt="search">
        <input v-model="search" type="text" placeholder="What are you looking for?" @change="goSearch()">
      </div>
      <div class="merchant-bar" @click="picker_1 = true">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <!-- 分类数据 -->
    <van-list  v-model="loading" :finished="finished" finished-text="......" loading-text="loading..." @load="onLoad">
      <goods-columns @cartClick="handleCart" :data="goodsList" :loading="goodsLoad" :columns="2"></goods-columns>
    </van-list>
    <!-- 商家导航 -->
    <van-popup v-model="picker_1" round position="bottom" class="merchant-bar" closeable>
      <p class="bar-title van-hairline--bottom">{{$store.state.merchant.title}}</p>
      <router-link :to="`/en/merchant/index/${$route.params.id}`"  class="module-cell van-hairline--bottom">
        <div class="left">
          <img src="@/assets/merchant/icon_dianpu@2x.png" alt="return">
          <span>Homepage</span>
        </div>
        <div class="right">
          <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
        </div>
      </router-link>
      <router-link :to="`/en/merchant/classify/${$route.params.id}`"  class="module-cell van-hairline--bottom">
        <div class="left">
          <img src="@/assets/merchant/icon_fenlei.png" alt="return">
          <span>Classification</span>
        </div>
        <div class="right">
          <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
        </div>
      </router-link>
      <router-link :to="`/en/merchant/chat/${$route.params.id}`"  class="module-cell van-hairline--bottom">
        <div class="left">
          <img src="@/assets/merchant/icon_kefu.png" alt="return">
          <span>Customer service</span>
        </div>
        <div class="right">
          <img src="@/assets/public/icon-fanhui@2x.png" alt="link">
        </div>
      </router-link>
    </van-popup>
    <!-- 选择SKU 加入购物车 -->
    <m-sku title="Add to Cart" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goods-id="goodsId"></m-sku>
    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>
    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>
import MSku         from '@/components/en/m-sku.vue'
import GoodsColumns from '@/components/en/goods-columns.vue'
import MAnimate     from '@/components/en/cart-animate.vue'
import CartFlex     from '@/components/en/cart-flex.vue'
import { goods, follow } from '@/api/zh/merchant.js'
export default {
  name:'Index',
  components:{  GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      rate: 4.5,
      loading: false,
      finished: false,
      picker_1: false,
      skuModal: false,
      start: false,
      start_dom: null,
      page: 1,
      goodsList: [],
      goodsLoad: false,
      goodsId: '',
      search: '',
    }
  },
  mounted(){
  
  },
  methods:{
    handleBack(){
      this.$router.go(-1)
    },
    // 上拉加载
    onLoad(){
      this.page++
      this.getGoods()
    },
    // 获取商品
    getGoods() {
      let params = { shops_id: this.$route.params.id, status: this.$route.query.status, page: this.page }
      this.goodsLoad = true
      goods(params).then(res => {
        if(res) {
          this.goodsList = [...this.goodsList, ...res.data.data]
          if(this.page >= res.data.last_page) {
            this.finished = true
          }
        } else {
          this.finished = true
          this.loading = false
        }
      }).finally( () => {
        this.loading = false
      })
    },
    // 搜索
    goSearch() {
      if(this.search != '') {
        this.$router.push( { path: '/en/search', query: {s: 'merchant', key: this.search, id: this.$route.params.id} })
      }
    },
    // 点击关注
    handleFollowed() {
      let params = { merchant_id: this.$route.params.id }
      follow(params).then(res => {
        if(res) {
          if(res.msg.length == 4) {
            this.$store.state.merchant.collect_num ++
            this.$store.state.merchant.is_follow = 2
          }else {
            this.$store.state.merchant.collect_num --
            this.$store.state.merchant.is_follow = 1
          }
          this.$notify({ type: 'success', message: res.msg })
        }
      })
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)
        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    }
  },
  created() {
    this.goodsList = []
    this.getGoods()
  }
}
</script>

<style lang="less" scoped>
@import './goods.less';
</style>
